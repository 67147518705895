<template>
  <v-card
    elevation="2"
    outlined
    style="width: 800px"
  >
    <v-card-title class="title text--primary text-left">
      {{ $t("app.terms.title") }}
    </v-card-title>
    <v-card-text class="text--primary text-left">
      <div class="termsText">
        <!-- Se også app.vue  -->
        <p>
          For at vi kan give dig adgang til portalen, har vi brug for din accept
          af vilkårene. Hvis du ikke accepterer, kan du desværre ikke bruge
          portalen. Vilkårene er:
        </p>
        <ul>
          <li>
            Samtykke til brugen af cookies, som er små filer, der bliver lagret
            på din computer, tablet eller mobil. Vi bruger kun cookies, der er
            nødvendige for, at portalen kan fungere rent teknisk, så vi kan
            levere vores service til dig.
          </li>
          <li>
            Samtykke til registrering af dit navn, arbejdstelefonnummer og
            arbejdsmail samt titel/stillingsbetegnelse. Denne registrering er
            udelukkende til brug for dit arbejde, og oplysningerne videregives
            ikke til tredjepart. Vi opbevarer oplysningerne så længe, du har en
            aktiv profil. Du kan til enhver tid kræve at få slettet
            oplysningerne, hvorefter du ikke længere kan bruge portalen.
          </li>
          <li>
            Anerkendelse af, at anvendelsen af portalen er på eget ansvar, og at
            du ikke må videregive følsomme personhenførbare data.
          </li>
          <li>
            Anerkendelse af, at brugernavn og kode er personligt, og at du under
            ingen omstændigheder må videregive det til andre eller på anden vis
            give andre adgang til portalen. Har du mistanke om, at andre
            uberettiget har fået adgang til portalen via misbrug af dit login,
            skal du omgående kontakte os.
          </li>
          <li>
            Anerkendelse af, at TPA solutions ikke er erstatningspligtig i
            forbindelse med it-nedbrud, svigtende adgang til data og/eller
            ødelæggelse af data, der skyldes hærværk, strømsvigt, computervirus,
            hacking, uroligheder, sabotage, naturkatastrofer, force
            majeur-situationer, lock out, strejke, blokade eller boykot.
          </li>
        </ul>
      </div>
      <div class="termsTextFooter mb-2">
        {{ $t("app.terms.textFooter") }}
      </div>
      <v-divider />
      <p
        v-if="loaded"
        class="text-right font-weight-bold font-italic mt-2"
      >
        {{ $t('app.terms.termsAcceptedOn') }}
        {{ model.data.termsAcceptedOnDate }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Terms",
  data() {
    return {
      model: Object,
      //view only
      loaded: false,
    };
  },
  created() {
    var vm = this;
    vm.$AuthService.api.get("user").then((response) => {
      vm.model.data = response.data;
      vm.loaded = true;
    });
  },
};
</script>